import React from 'react';
import { Layout } from '../components';
import { Link } from 'gatsby';

class ProductLandingPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            products: []
        }
    }

    componentDidMount() {
        if(this.props.data) {
            const products = this.props.data.allMarkdownRemark.nodes.map(product => {
                const productParsed = product.frontmatter;
                productParsed.url = product.fields.url;
                return productParsed;
            });
            this.setState({products});
        }
    }
    
    render() {
        const {products} = this.state;
        return (
            <Layout {...this.props}>
                <div id="products-landing">
                    <div id="wrapper">
                        <section id="main" className="wrapper">
                            <div className="inner">
                                <h1>Products</h1>
                                {products && products.length > 0 && (
                                    <div className="row">
                                        {products.map((product, index) => {
                                            const mainImage = product.images[0];
                                            return (
                                                <div className="column column-lg-3 column-md-6 product" key={index}>
                                                    <div className="product-wrap">
                                                        <Link to={product.url} className="product-image-link">
                                                            <div className="image-wrap product-image">
                                                                <img src={mainImage} alt="" />
                                                            </div>
                                                        </Link>
                                                        <div className="d-flex flex-columns justify-content-between product-info-wrap">
                                                            <div className="product-info-top">
                                                                <Link to={product.url} className="product-title-link">
                                                                    <h3>{product.title}</h3>
                                                                </Link>
                                                                {product.wetlbs && (
                                                                    <div className="text-muted product-info">{product.wetlbs}</div>
                                                                )}
                                                            </div>
                                                            <div className="product-info-bottom">
                                                                <div className="product-price">${product.price}</div>
                                                                <Link to={product.url}>View Product</Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                )}
                            </div>
                        </section>
                    </div>
                </div>
            </Layout>
        )
    }
}
    
export const query = graphql`
query productsQuery {
    allMarkdownRemark(filter: {frontmatter: {template: {eq: "product"}}}) {
        nodes {
            frontmatter {
                title
                price
                images
                wetlbs
                template
            }
            fields {
                url
            }
        }
    }
}
`;
    
export default ProductLandingPage;